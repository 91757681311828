import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Tooltip } from '@mui/material';
import InlineAlert from 'components/ui-components/InlineAlert';
import Typo from 'components/ui-base/Typo';
import { ResetOverwriteButton } from 'components/ui-components-cape/ResetOverwriteButton';
import FieldSetLabel from './label';
import { FieldSetActions } from './actions';
import './../styles/main.scss';

/**
 * FieldSet
 * This is a wrapper around the input fields that allows you to display a label and tooltip.
 */
export default class FieldSet extends Component {
    static propTypes = {
        alignLeft: PropTypes.bool,
        children: PropTypes.node,
        /** Label to display above the component */
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** Settings this adds a ( i ) icon next to the label which displays this text when hoovered. To customize the placement, set tooltipPlacement. */
        tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** Placement for the tooltip, possible values: 'bottom-end', 'bottom-start', 'bottom', 'left-end', 'left-start', 'left', 'right-end', 'right-start', 'right', 'top-end', 'top-start', 'top' */
        tooltipPlacement: PropTypes.string,
        /** Error */
        error: PropTypes.string,
        fullWidth: PropTypes.bool
    };

    static defaultProps = {
        alignLeft: false,
        label: '',
        tooltip: '',
        tooltipPlacement: 'bottom'
    };

    render() {
        const {
            alignLeft,
            children,
            label,
            tooltip,
            tooltipPlacement,
            compact = false,
            error,
            fullWidth = false,
            helperText,
            tourData,
            hidden = false,
            onOverwriteReset,
            extraLabelText,
            actions,
            inputKey
        } = this.props;

        let style = {};
        if (compact) {
            style = { width: '50%', display: 'inline-block' };
        } else if (hidden) {
            style = { display: 'none' };
        }

        // If a tour selector is available add it to the rendered Element
        let tourSelector;
        if (tourData) {
            tourSelector = this.props.children.props.model;
        } else {
            tourSelector = 'none';
        }

        return (
            <div
                className={'input-field-set ' + (fullWidth ? 'input-field-set--full-width' : '')}
                data-tour-selector={tourSelector}
                data-input-key={inputKey}
                style={style}>
                {(label || extraLabelText || onOverwriteReset || actions) && (
                    <div className="input-field-set__label-container">
                        {label && <FieldSetLabel label={label} tooltip={tooltip} placement={tooltipPlacement} />}

                        {extraLabelText && (
                            <Tooltip placement="top" title={extraLabelText.tooltip}>
                                <div className="input-field-set__label-container__extra-label-text">{extraLabelText.text}</div>
                            </Tooltip>
                        )}
                        {onOverwriteReset && <ResetOverwriteButton size="small" onClick={onOverwriteReset} />}
                        {actions && <FieldSetActions actions={actions} />}
                    </div>
                )}
                {error && <InlineAlert>{error}</InlineAlert>}
                <div className={`input-field-set__fields ${alignLeft ? 'left' : ''}`}>{children}</div>
                {helperText && (
                    <div className="input-field-set__helper-text">
                        <Typo variant="caption" color="secondary">
                            {helperText}
                        </Typo>
                    </div>
                )}
            </div>
        );
    }
}
